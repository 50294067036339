<template>
	<section class="hero">
		<div class="background-img hidden-md-and-down">
			<picture>
				<source
					type="image/avif"
					alt="Úvodní grafika webu autocasek.cz"
					:srcset="require('@/assets/hero-bg.avif')"
				/>
				<img
					:src="require('@/assets/hero-bg.jpg')"
					alt="Úvodní grafika webu autocasek.cz"
				/>
			</picture>
		</div>
		<div class="background-img-mobile hidden-lg-and-up">
			<picture>
				<source
					type="image/avif"
					alt="Úvodní grafika webu autocasek.cz"
					:srcset="require('@/assets/hero-bg.avif')"
				/>
				<img
					:src="require('@/assets/hero-bg.jpg')"
					alt="Úvodní grafika webu autocasek.cz"
				/>
			</picture>
		</div>
		<div class="content">
			<div class="hero-block mt-md-n15 ml-lg-n15 ml-xl-0">
				<v-container>
					<img
						class="logo"
						:src="require('@/assets/logo.svg')"
						alt="Logo firmy AutoCásek, s.r.o."
					/>
					<base-heading title="Flotila bez zbytečných ALE" />
					<base-body :v-intersect="isVisible" class="hero-text">
						<div class="hero-text-item">
							<div>
								<v-icon class="primary--text"
									>mdi-menu-right</v-icon
								>
							</div>
							<div>
								Správa vozových parků
							</div>
						</div>
						<div class="hero-text-item">
							<div>
								<v-icon class="primary--text"
									>mdi-menu-right</v-icon
								>
							</div>
							<div>
								Operativní pronájmy vozidel
							</div>
						</div>
						<div class="hero-text-item">
							<div>
								<v-icon class="primary--text"
									>mdi-menu-right</v-icon
								>
							</div>
							<div>
								Zprostředkování pojištění a prodeje vozidel
							</div>
						</div>
						<div class="hero-text-item">
							<div>
								<v-icon class="primary--text"
									>mdi-menu-right</v-icon
								>
							</div>
							<div>
								Poskytování náhradních vozidel
							</div>
						</div>
					</base-body>

					<div
						:class="
							$vuetify.breakpoint.smAndDown
								? 'flex-column align-start'
								: 'align-center'
						"
						class="d-flex flex-wrap"
					>
						<base-btn @click="goTo('#sluzby')">
							Prohlédnout nabídku služeb
						</base-btn>

						<span class="font-weight-bold ml-6 mr-4 my-4"
							>nebo</span
						>

						<base-btn
							:ripple="false"
							class="pa-1"
							height="auto"
							@click="goTo('#lets-cooperate')"
							text
						>
							Přejít na kontakt
						</base-btn>
					</div>
				</v-container>
			</div>
		</div>
		<div class="scroll-icon text-center" @click="goTo('#features')">
			<div class="caption text-uppercase">Pokračujte dále</div>
			<v-icon color="white" :large="true">mdi-chevron-down</v-icon>
		</div>
	</section>
</template>

<script>
	import goTo from 'vuetify/es5/services/goto';

	export default {
		name: 'SectionHero',

		provide: {
			theme: { isDark: true },
		},

		data() {
			return {
				isVisible: false,
			};
		},

		methods: {
			goTo,
		},

		computed: {
			minHeight() {
				const height = this.$vuetify.breakpoint.mdAndUp
					? '100vh'
					: '50vh';
				return `calc(${height} - ${this.$vuetify.application.top}px)`;
			},
		},
	};
</script>
<style scoped>
	img {
		max-width: 100%;
		height: auto;
	}

	.hero {
		width: 100vw;
		height: 100vh;
		display: block;
		position: relative;
		overflow: hidden;
		background-color: #000;
	}

	.hero .background-img,
	.background-img-mobile {
		position: absolute;
		display: block;
		top: 0px;
		width: 100%;
		height: 100%;
	}

	.hero .background-img-mobile {
		position: absolute;
		display: block;
		right: 0px !important;
		top: 0px;
	}

	.hero .background-img img {
		width: 100%;
		opacity: 0.25;
	}

	.hero .background-img-mobile img {
		height: 100%;
		opacity: 0.25;
	}

	.hero .content {
		display: flex;
		position: absolute;
		z-index: 10;
		color: #fff;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
	}

	.hero .content .hero-block {
		display: block;
		position: relative;
		width: 100%;
	}

	.hero-text {
		font-size: 1.2rem !important;
		width: 100%;
		transform: translateY(1rem);
	}

	.logo {
		width: 15rem;
		transform: translateY(7.5rem);
	}

	@media not screen and (prefers-reduced-motion) {
		.hero .background-img {
			animation: scaleAnim 30s ease-in-out infinite;
		}
	}

	@media screen and (max-width: 1000px) {
		.hero .content .hero-block {
			top: -10rem;
		}
	}

	@keyframes scaleAnim {
		50% {
			transform: scale(1.2);
		}
	}

	@keyframes moveArrow {
		50% {
			transform: translateY(2rem);
		}
	}

	.hero-text-item {
		display: flex;
		align-items: top;
		margin-top: 0.25rem;
	}

	.scroll-icon {
		display: block;
		position: absolute;
		width: 100%;
		margin: auto;
		z-index: 999;
		color: #fff;
		bottom: 0.5rem;
		transition-duration: 2s;
		cursor: pointer;
	}

	.scroll-icon:hover {
		transform: translateY(-0.5rem);
		transition-duration: 2s;
		color: #f39503;
	}
</style>
